export const debugMessage = process.env.DEBUG_ENABLED ? console.log.bind(console) : () => {};

export function getCookie(name: string) {
  const matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function isMobile() {
  return window.matchMedia("only screen and (max-width: 480px)").matches;
}
